<template>
  <vCard :col="12" header="Opties" :actions="actions">
    <div class="row" v-if="vehicleOptionsWithoutGroup.length === 0">
      <template v-for="vehicleOptionCategory in vehicleOptionCategories">
        <div class="col-md-4 col-sm-12">
          <h5>{{ vehicleOptionCategory[0].vehicle_option_category.name }}</h5>
          <template v-for="vehicleOptionGroup in vehicleOptionCategory">
            <v-checkbox v-model="vehicleOptionGroup.$model"
                        :placeholderText="vehicleOptionGroup.name"
                        :show-errors="true"
                        :disabled="false"/>
          </template>
        </div>
      </template>
    </div>
    <div v-else>
      <p style="color: red">Voordat de opties ingevuld kunnen worden, moeten er eerst nog een
        {{ vehicleOptionsWithoutGroup.length }} worden gemapped naar groepen </p>
      <table style="width: 100%">
        <template v-for="vehicleOption in vehicleOptionsWithoutGroup">
          <vehicleOptionTableRow :vehicleOption="vehicleOption"
                                 :key="vehicleOption.id"
                                 @delete="checkAllIsMapped"/>
        </template>
      </table>
    </div>
  </vCard>
</template>

<script>
import vehicleOptionTableRow from '../../vehicleOptions/components/VehicleOptionTableRow'

export default {
  name: 'purchaseList',
  components: {
    vehicleOptionTableRow
  },
  created() {
    this.checkAllIsMapped()
  },
  props: {
    vehicleID: {
      required: true
    }
  },
  computed: {
    activatedOptions: {
      get() {
        let options = []
        Object.keys(this.vehicleOptionCategories).forEach(index => {
          this.vehicleOptionCategories[index].forEach((option => {
            if (option?.$model?.value) {
              options.push(option)
            }
          }))
        })

        return options
      }
    }
  },
  data() {
    return {
      actions: [
        {
          icon: '<i class="material-icons">refresh</i>',
          fn: () => {
            this.checkAllIsMapped()
          }
        },
        {
          icon: '<i class="material-icons">save</i>',
          fn: () => {
            this.save()
          }
        }
      ],
      vehicleOptionCategories: [],
      vehicleOptionsWithoutGroup: []
    }
  },
  methods: {
    async getVehicleOptionGroups() {
      let {data} = await this.$api.vehicleOptionGroup.list()

      let vehicleOptionsOrderByCategory = {}
      data.forEach((vehicleOptionGroup) => {
        if (!vehicleOptionsOrderByCategory.hasOwnProperty(vehicleOptionGroup.vehicle_option_category.name)) {
          Object.assign(vehicleOptionsOrderByCategory, {[vehicleOptionGroup.vehicle_option_category.name]: []})
        }
        vehicleOptionsOrderByCategory[vehicleOptionGroup.vehicle_option_category.name].push(vehicleOptionGroup)
      })
      this.vehicleOptionCategories = vehicleOptionsOrderByCategory

      await this.setActiveGroups()
    },
    async setActiveGroups() {
      let activeOptionGroups = []
      let {data} = await this.$api.vehicleOption.getVehicleOptions({id: this.vehicleID})

      data.forEach(option => {
        if (option.group) {
          let id = option.group.replace('/vehicle_option_groups/', '')
          activeOptionGroups.push(parseInt(id))
        }
      })

      Object.keys(this.vehicleOptionCategories).forEach(category => {
        this.vehicleOptionCategories[category].forEach(group => {
          if (activeOptionGroups.includes(group.id)) {
            group.$model.value = true
          }
        })
      })

    },
    async checkAllIsMapped() {
      // Clear array
      // let {data} = await this.$api.vehicleOption.getVehicleOptions({id: this.vehicleID})
      let data

      this.vehicleOptionsWithoutGroup = []
      if (data) {
        data.forEach(vehicleOption => {
          if (!vehicleOption.group) {
            this.vehicleOptionsWithoutGroup.push(vehicleOption)
          }
        })
      }

      if (this.vehicleOptionsWithoutGroup.length === 0) {
        await this.getVehicleOptionGroups()
      }
    },
    async save() {
      try {
        let {data} = await this.$api.vehicle.setOptionGroups(this.vehicleID, this.activatedOptions)
        console.log(data)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>
table > tr {
  border-bottom: 1px solid lightgray;
}
</style>
